import Image from 'next/image';

import {
  TextKeyValuePair,
  TextWithIcon,
  ToggleSwitch
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const CreditRequestRecordHeader = () => (
  <div className='w-full flex justify-between'>
    <ToggleSwitch
      {...{
        label: 'Enable Credit For Use',
        labelStyle: 'text-white font-medium',
        toggleStyle: 'credit-toggle'
      }}
    />
    <div className='flex gap-4'>
      <TextWithIcon
        {...{
          alt: 'request credit',
          className: 'shadow-card py-1 px-2 rounded bg-white',
          icon: 'wallet-icon-yellow.svg',
          iconHeight: 16,
          iconWidth: 16,
          label: '25th AUG 2024',
          labelStyle: 'text-xs font-medium text-warning-dark'
        }}
      />
      <TextWithIcon
        {...{
          alt: 'approve credit',
          className: 'shadow-card py-1 px-2 rounded bg-white',
          icon: 'wallet-icon-green.svg',
          iconHeight: 16,
          iconWidth: 16,
          label: '25th AUG 2024',
          labelStyle: 'text-xs font-medium text-green'
        }}
      />
    </div>
  </div>
);
const CreditRequestRecordBody = () => (
  <div className='w-full flex justify-between mt-5'>
    <div className='flex items-center gap-3'>
      <Image
        alt='approve credit'
        height={0}
        src={`${staticMediaStoreBaseURL}/icons/wallet-icon-white.svg`}
        style={{ height: 44, width: 44 }}
        width={0}
      />
      <TextKeyValuePair
        {...{
          label: 'Approved: 100,000',
          labelClass: 'text-white text-lg font-semibold',
          spaceTop: 'mt-0 !gap-0',
          value: 'Requested: 100,000',
          valueClassName: 'text-white text-xs font-medium'
        }}
      />
    </div>
    <TextKeyValuePair
      {...{
        label: 'Reviewed By: Harsh Talati',
        labelClass: 'text-white text-lg font-semibold',
        spaceTop: 'mt-0 !gap-0',
        value: 'Requested By: Maamar Zaine',
        valueClassName: 'text-white text-xs font-medium'
      }}
    />
  </div>
);
const CreditRequestRecordFooter = () => (
  <div className='flex flex-col gap-2 w-full p-4'>
    <TextKeyValuePair
      {...{
        className: 'flex-row',
        label: 'Payment Terms (in days):',
        labelClass: 'text-dim-gray text-sm font-medium',
        value: '30',
        valueClassName: 'text-dim-gray text-sm'
      }}
    />
    <TextKeyValuePair
      {...{
        className: 'flex-row',
        label: 'Reason For Credit:',
        labelClass: 'text-dim-gray text-sm font-medium',
        value:
          'Lorem ipsum is a placeholder text commonly used to demonstrate the visual form ',
        valueClassName: 'text-dim-gray text-sm'
      }}
    />
  </div>
);

const ApprovedCreditRequest = () => (
  <div className='flex flex-col rounded-lg border border-[#F3F3F6] shadow-navigate-cart mb-20'>
    <div className='bg-brand-gradient text-white rounded-t-lg p-4'>
      <CreditRequestRecordHeader />
      <CreditRequestRecordBody />
    </div>
    <CreditRequestRecordFooter />
  </div>
);

export default ApprovedCreditRequest;
