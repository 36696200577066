import {
  convertToCurrencyFormat,
  parseFormatPriceValueFromAPI
} from '@/lib/numberStringUtils';
import { isEmptyOrNull } from '@/lib/utils';
import * as HttpService from '@/services/http.service';
import {
  ADD_HOST_CREDIT_ENTITY,
  GET_HOST_CREDIT_ENTITY_HOST_LIST,
  GET_HOST_CREDIT_ENTITY_PROFILE_DETAILS,
  GET_OPS_USER_LIST
} from '@/services/url.service';

const getHostCreditEntityHostListAPI = ({ hostCreditEntityId }) =>
  HttpService.getWithAuth(
    GET_HOST_CREDIT_ENTITY_HOST_LIST({ hostCreditEntityId })
  );

const getHostCreditEntityProfileDetailsAPI = ({ hostCreditEntityId }) =>
  HttpService.getWithAuth(
    GET_HOST_CREDIT_ENTITY_PROFILE_DETAILS({ hostCreditEntityId })
  );

const getOpsUserListAPI = () => HttpService.getWithAuth(GET_OPS_USER_LIST());

const addHostCreditEntityAPI = (dataToSubmit) =>
  HttpService.postWithAuth(ADD_HOST_CREDIT_ENTITY(), dataToSubmit);

export const fetchHostCreditEntityHostList = async ({
  hostCreditEntityId,
  setHostCreditEntityHostList,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { entity, message, status } = await getHostCreditEntityHostListAPI({
    hostCreditEntityId
  });
  if (status && !isEmptyOrNull(entity)) {
    setHostCreditEntityHostList(entity);
  } else {
    setShowToast({
      message,
      show: true,
      status: false
    });
  }
  setLoading(false);
};

export const fetchHostCreditEntityProfileDetails = async ({
  hostCreditEntityId,
  setHostCreditEntityProfileDetails,
  setLoading,
  setShowToast
}) => {
  setLoading(true);
  const { entity, message, status } =
    await getHostCreditEntityProfileDetailsAPI({
      hostCreditEntityId
    });
  if (status && !isEmptyOrNull(entity)) {
    setHostCreditEntityProfileDetails(entity);
  } else {
    setShowToast({
      message,
      show: true,
      status: false
    });
  }
  setLoading(false);
};

export const fetchOpsUserList = async ({ setOpsUserList, setShowToast }) => {
  const { status, entity, message } = await getOpsUserListAPI();
  if (status && !isEmptyOrNull(entity?.rows)) {
    const { rows } = entity;
    setOpsUserList(rows);
  } else {
    setOpsUserList([]);
    setShowToast({
      message,
      show: true,
      status
    });
  }
};

export const createHostCreditEntity = async ({
  dataToSubmit,
  setError,
  setSelectedHostCreditEntityName,
  setShowToastContainer,
  setValueOnParent
}) => {
  const { entity, status, message } =
    await addHostCreditEntityAPI(dataToSubmit);
  const { isExists } = entity || {};
  if (isExists) {
    setError('name', {
      message: 'Entity Name already exists.',
      type: 'custom'
    });
  } else {
    setSelectedHostCreditEntityName(entity.hostCreditEntity.name);
    setValueOnParent('hostCreditEntityId', entity.hostCreditEntity.id);
    setShowToastContainer({
      message,
      show: true,
      status
    });
  }
};

export const getCreditEntityStatisticsParamaters = ({
  eventStatistics: {
    averageUserEventValue,
    grossUserEventValue,
    userEventCount,
    userEventsConverted
  }
} = {}) => ({
  grossUserEventValue: convertToCurrencyFormat({
    value: parseFormatPriceValueFromAPI(grossUserEventValue)
  }),
  userEventCount: {
    label: 'Events Inquired',
    value: userEventCount
  },
  userEventsConverted: {
    alt: 'book-event-icon',
    icon: 'book-event-fill-icon.svg',
    label: 'Booked Events',
    value: userEventsConverted
  },
  averageMarginPercent: {
    alt: 'margin-icon',
    icon: 'margin-icon.svg',
    label: 'Lifetime Margin %',
    value: 'NA'
  },
  averageUserEventValue: {
    alt: 'event-gev-icon',
    icon: 'gev-fill-icon.svg',
    label: 'AEV',
    value: convertToCurrencyFormat({
      value: parseFormatPriceValueFromAPI(averageUserEventValue),
      withCurrencyPrefix: false
    })
  }
});

export const hostCreditEntityProfileTabs = {
  PROFILE: {
    id: '#host-credit-entity-profile-tab',
    label: 'Profile'
  },
  ACTIVE_CREDIT: {
    icon: {
      alt: 'host credit entity active credit',
      name: 'notification.svg',
      style: { height: 24, width: 24 }
    },
    id: '#host-credit-entity-active-credit-tab',
    label: 'Active Credit'
  },
  CREDIT_HISTORY: {
    id: '#host-credit-entity-credit-history-tab',
    label: 'Credit History'
  }
};

export const hostCreditEntityProfileStepFieldErrorConfig = {
  [hostCreditEntityProfileTabs.PROFILE.id]: [],
  [hostCreditEntityProfileTabs.ACTIVE_CREDIT.id]: [],
  [hostCreditEntityProfileTabs.CREDIT_HISTORY.id]: []
};
