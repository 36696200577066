import Image from 'next/image';

import {
  Button,
  InputField,
  Text,
  TextArea,
  TextKeyValuePair
} from '@/components/atomic/nuclei';
import { staticMediaStoreBaseURL } from '@/config/common';

const ReviewCreditRequestSummary = () => (
  // TODO: HD when open class will be  isopen?'border-neutral rounded-t-lg':'border-brand rounded-lg'

  <div className='flex gap-2 items-center border border-brand rounded-lg py-3 px-4'>
    <Image
      alt='new credit request'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/notification.svg`}
      style={{ height: 24, width: 24 }}
      width={0}
    />
    <div className='flex gap-1'>
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'New Credit Request: ',
          labelClass: 'font-medium',
          value: 'Maamar Zaine',
          valueClassName: 'font-medium'
        }}
      />
      <TextKeyValuePair
        {...{
          className: 'flex-row',
          label: 'has applied for a credit facility of ',
          labelClass: 'font-light',
          value: 'AED 120,000.',
          valueClassName: 'font-medium'
        }}
      />
    </div>
    <Text
      {...{
        className:
          'in-progress text-xs font-medium rounded-lg px-2 py-1 ml-auto',
        content: 'Pending'
      }}
    />
    <Image
      alt='open credit request'
      className='cursor-pointer'
      height={0}
      src={`${staticMediaStoreBaseURL}/icons/right-angle-red.svg`}
      style={{ height: 16, width: 16 }}
      width={0}
    />
  </div>
);

const ReviewCreditRequestForm = () => (
  <div className='flex flex-col gap-4 rounded-b-lg pt-5 pb-6 px-20 border border-neutral'>
    <div className='flex gap-4'>
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'requestAmount',
          iconURL: 'budget-icon-red.svg',
          inputGroup: 'flex flex-col gap-2 w-1/2',
          label: 'Credit Request Amount *',
          labelClass: 'text-nero text-base font-medium',
          maxLength: 6,
          placeholder: 'Enter Credit Amount',
          register: () => {}
        }}
      />
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'days',
          iconURL: 'trn-icon-red.svg',
          inputGroup: 'flex flex-col gap-2 w-1/2',
          label: 'Payment Terms (in days) *',
          labelClass: 'text-nero text-base font-medium',
          maxLength: 6,
          placeholder: '0 - 180 days (payback terms)',
          register: () => {}
        }}
      />
    </div>
    <InputField
      {...{
        className: 'rounded-lg pl-10',
        dbName: 'days',
        iconURL: 'trn-icon-red.svg',
        inputGroup: 'flex flex-col gap-2 w-full',
        label: 'Reason For Credit',
        labelClass: 'text-nero text-base font-medium',
        maxLength: 6,
        placeholder: 'Reason',
        register: () => {}
      }}
    />
    <div className='flex gap-4'>
      <Button
        {...{
          className: 'border border-brand rounded-lg py-3 font-medium',
          label: 'Approve'
        }}
      />
      <Button
        {...{
          className:
            'border border-[#E5E5EB] text-dim-gray rounded-lg py-3 font-medium',
          label: 'Reject',
          disabled: true
        }}
      />
    </div>
    <div className='flex gap-4'>
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'requestAmount',
          iconURL: 'budget-icon-red.svg',
          inputGroup: 'flex flex-col gap-2 w-1/2',
          label: 'Maximum Approved Credit *',
          labelClass: 'text-nero text-base font-medium',
          maxLength: 6,
          placeholder: '100,000',
          register: () => {}
        }}
      />
      <InputField
        {...{
          className: 'rounded-lg pl-10',
          dbName: 'days',
          iconURL: 'user-icon-red.svg',
          inputGroup: 'flex flex-col gap-2 w-1/2',
          label: 'Approved By',
          labelClass: 'text-nero text-base font-medium',
          maxLength: 6,
          register: () => {}
        }}
      />
    </div>
    <TextArea
      {...{
        className: 'rounded-lg pl-10',
        inputGroup: 'flex flex-col gap-2 w-full',
        labelClass: 'text-nero text-base font-medium',
        iconURL: 'solution-design-icon-red.svg',
        label: 'Notes',
        register: () => {}
      }}
    />
  </div>
);

const PendingCreditRequest = () => (
  <div>
    <ReviewCreditRequestSummary />
    <ReviewCreditRequestForm />
  </div>
);

export default PendingCreditRequest;
